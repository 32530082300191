.section_card_container {
  width: 100%;
  margin-bottom: 2rem;
}
.section_card_title {
  align-items: center;
  border-radius: 5px;
  padding: 8px 0 0 20px;
  letter-spacing: 1px;
  font-weight: 500;
  height: 40px;
  width: 100%;
  margin: 0 auto;
  color: white;
  background-image: linear-gradient(
    90deg,
    #034078 0%,
    rgb(76, 123, 167) 100%
  );

  box-shadow: rgba(99, 99, 99, 0.2) 0px 5px 8px -5px;
  margin-bottom: 1rem;
  position: relative;
}
.section_card_title_text {
  font-size: 1rem;
  width: 70%;
}
.card_icon_section {
  position: absolute;
  top: 15%;
  right: 1.7%;
}
.section_card_content {
  color: #034078;
  width: 86%;
  margin: 0 auto;
  white-space: pre-wrap;
  /* background-color: #03407827; */
}

.section {
  min-width: 210px;
  padding: 2px 10px 0px 15px;
}
