.features_container{
    width: 80%;
    height: 600px;
    /* margin-left: 80px; */
    margin:5rem auto 6rem auto;

}
.features_box{
    border-radius: 5px;
    background-position: center;
    background-size: cover;
    background-image: linear-gradient(to left top,rgba(3, 64, 120,0.9),rgba(40, 99, 153,0.9),rgba(169, 198, 224,0.9)),url(../../images/book-library-with-open-textbook-long.jpg);
    height:90%;
    padding:3rem 0 3.5rem 0;

}
.features-top{
    width: 100%;
    margin-bottom: 2rem;
    height: 23.5%;
}
.features-middle{
    display: flex;
    width: 100%;
    height: 40%;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.features-bottom{
    width: 100%;
    height: 30%;
}
.feature-item{
    height: 80%;
    width: 30%
}

.feature_span{
    font-size: 1.7rem;
    display: block;
    color: white;
    text-align: center;
    font-weight: 400;
}

.single{
    margin: 0 auto;
}
.feature-img{
    background-image: url(../../images/book-library-with-open-textbook.jpg);
    background-size: cover;
    border-radius: 50px;
    width: 30%;
    height: 90%;
}