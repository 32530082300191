.sections_container {
  width: 80%;
  margin: 0 auto 7rem auto;
}
.sections_chapter_head {
  width: 100%;
}
.sections_chapter_title {
  width: 80%;
  margin-top: -2rem;
  font-size: 3rem;
  font-size: 4rem;
  font-weight: 500;
  color: transparent;
  margin-bottom: 0.5rem;
  background-image: linear-gradient(#034078, #034078, #98b8d6);
  background-clip: text;
  -webkit-background-clip: text;
}
.sections_constitution_title {
  font-size: 1.5rem;
  color: #034078;
  margin-bottom: 0.5rem;
}
.sections_body {
  width: 100%;
}
.sections_add_button_container {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
}
