.banner {
  height: 100px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: left;
  background-position: center;
  background-image: linear-gradient(
    to left top,
    rgba(3, 64, 120, 0.4),
    rgba(40, 99, 153, 0.5),
    rgba(169, 198, 224, 0.6)),
    url(../../images/book-library-with-open-textbook-long.jpg)
  ;
}
.banner_text {
  width: 80%;
  color: #fff;
  font-size: 2rem;
  margin: 0 auto;
}
