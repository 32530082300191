section {
  width: 100%;
  display: inline-block;
  background: #034078;
  /* height: 50vh; */
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  text-decoration: underline;
}

.footer-distributed {
  background: #034078;
  margin: 0 auto;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  width: 80%;
  text-align: left;
  font: bold 16px sans-serif;
  padding: 55px 50px;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right {
  display: inline-block;
  vertical-align: top;
}

/* Footer left */

.footer-distributed .footer-left {
  width: 35%;
}

/* The company logo */

.footer-distributed h3 {
  color: #ffffff;
  /* font: normal 36px 'Open Sans', cursive; */
  margin: 0;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: bold;
}

.footer-distributed h3 span {
  color: white;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: bold;
}

/* Footer links */

.footer-distributed .footer-links {
  color: #ffffff;
  margin: 20px 0 12px;
  padding: 0;
}

.footer-distributed .footer-links .some_a {
  display: inline-block;
  line-height: 1.8;
  font-weight: 400;
  text-decoration: none;
  color: inherit;
}

.footer-distributed .footer-company-name {
  color: lightseagreen;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

/* Footer Center */

.footer-distributed .footer-center {
  width: 35%;
}

.footer-distributed .footer-center i {
  color: #ffffff;
  font-size: 25px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  margin: 10px 15px;
  vertical-align: middle;
}

.footer-distributed .footer-center i.fa-envelope {
  font-size: 17px;
  line-height: 38px;
}

.footer-distributed .footer-center p {
  display: inline-block;
  color: #ffffff;
  font-weight: 400;
  vertical-align: middle;
  margin: 0;
}

.footer-distributed .footer-center p span {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 2;
}

.footer-distributed .footer-center p a {
  color: lightseagreen;
  text-decoration: none;
}

.footer-distributed .footer-links a{
  font-weight: 300;
  font-size: 15px;
  border-right:1.5px solid white;
  left: 0;
  color: #fff;
  display: inline-block;
  padding: 0 10px;
  line-height: 0;
}
.footer-distributed .footer-links a:last-child{
    border-right:1.5px solid transparent;
}
.footer-distributed .footer-links a:first-child{
    padding-left: 0;
}

.footer-distributed .footer-links .link-1:before {
  content: none;
}

/* Footer Right */

.footer-distributed .footer-right {
  width: 30%;
}

.footer-distributed .footer-company-about {
  line-height: 20px;
  color: lightseagreen;
  font-size: 13px;
  font-weight: normal;
  margin: 0;
}

.footer-distributed .footer-company-about span {
  display: block;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}

.footer-distributed .footer-icons {
  margin-top: 25px;
}

.footer-distributed .footer-icons .iconX {
  display: inline-block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  border-radius: 2px;

  font-size: 20px;
  color: #ffffff;
  text-align: center;
  line-height: 35px;

  margin-right: 3px;
  margin-bottom: 5px;
}

@media screen and (max-width: 1024px){
  .footer-distributed .footer-left {
    width: 33%;
  }
  .footer-distributed .footer-center {
    width: 37%;
  }
  .footer-distributed .footer-right {
    width: 30%;
  }
  .footer-distributed h3 {
    font-size: 1rem;
    font-weight: bold;
  }
  
  .footer-distributed h3 span {
    font-size: 1rem;
  }
  
  /* Footer links */
  
  .footer-distributed .footer-links {
    margin: 10px 0 6px;
  }
  
  .footer-distributed .footer-links .some_a {
    line-height: 1.4;
    font-weight: 300;
    font-size: 11px;
  }
  
  .footer-distributed .footer-company-name {
    font-size: 10px;
  }

  /* Footer Center */
.footer-distributed .footer-center i {
  font-size: 20px;
  width: 30px;
  height: 30px;
  line-height: 20px;
  margin: 5px 10px;
}

.footer-distributed .footer-center i.fa-envelope {
  font-size: 14px;
  line-height: 28px;
}

.footer-distributed .footer-center p {
  font-size: 12px;
  font-weight: 300;

}

.footer-distributed .footer-center p span {
  font-size: 12px;
  line-height: 1;
}

.footer-distributed .footer-links a{
  font-weight: 300;
  font-size: 14px;
  border-right: 1px solid white;
  padding: 0 3px;
 
}
/* Footer Right */

.footer-distributed .footer-company-about {
  line-height: 15px;
  font-size: 13px;
}

.footer-distributed .footer-company-about span {
  display: block;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}

.footer-distributed .footer-icons {
  margin-top: 20px;
}




}

/* If you don't want the footer to be responsive, remove these media queries */

@media (max-width: 880px) {
  .footer-distributed {
    font: bold 14px sans-serif;
  }

  .footer-distributed .footer-left,
  .footer-distributed .footer-center,
  .footer-distributed .footer-right {
    display: block;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }

  .footer-distributed .footer-center i {
    margin-left: 0;
  }
}
