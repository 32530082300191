  
  .about-section_top {
    padding: 50px;
    text-align: center;
    background-image: linear-gradient(
      to left top,
      rgba(3, 64, 120, 0.85),
      rgba(40, 99, 153, 0.85),
      rgba(169, 198, 224, 0.85)),
      url(../../images/about-us.jpg)
    ;
    color: white;
    margin-bottom: 2rem;
    margin-top: -4rem;
    background-size: cover;
    background-position: center;
  }

  .about-section_top_contents{
    width: 80%;
    margin: 0 auto;
  }

  .about_our_team{
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
    margin-bottom: 2rem;
    color: #034078;
  }
  
 .about_card_container{
    width: 80%;
    margin: 0 auto 1rem auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;

 }
  
  
 
