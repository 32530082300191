.contact_section_top {
  padding: 100px;
  text-align: center;
  background-image: linear-gradient(
    to left top,
    rgba(3, 64, 120, 0.5),
    rgba(40, 99, 153, 0.6),
    rgba(169, 198, 224, 0.7)),url(../../images/contactus2.jpg);
  color: white;
  margin-bottom: 2rem;
  margin-top: -4rem;
  background-size: cover;
  background-position: center;
}
.contact_section_top_contents {
  width: 80%;
  margin: 0 auto;
}
.contact_secttion_main_head{
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: #034078;
  margin-bottom: 1rem;
}
.contact_section_main{
    display: flex;
    width: 80%;
    margin: 0 auto 3rem auto;
}
.contact_section_main_left{
    width: 50%
}
.contact_section_main_right{
    width: 50%
}
.contact_section_main_left_top{
  margin: 0px 0 10px 0;
}
.contact_section_main_left_top-text{
  font-size: 1.5rem;
  color: #034078;
}
.contact_section_main_left_bottom{
  margin: 10px 0;
  color: #034078;
}
.contact_section_main_left_bottom-text{
  font-size: 1.2rem;
}