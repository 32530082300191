.login_container{
    display: flex;
    margin: 0 auto 3rem auto;
    width: 80%;
    justify-content: space-between;
}
.login_login_container{
    width: 40%;
}
.login_signup_container{
    width: 40%;
}
.login_top_text{
    color: #034078;
    line-height: 1;
    margin-bottom: 1rem;
}
.top_text_large{
    font-size: 2rem;
    font-weight: bold;
}


.lds-facebook {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 20px;
  }
  .lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 4px;
    width: 6px;
    background: #fff;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .lds-facebook div:nth-child(1) {
    left: 4px;
    animation-delay: -0.24s;
  }
  .lds-facebook div:nth-child(2) {
    left: 16px;
    animation-delay: -0.12s;
  }
  .lds-facebook div:nth-child(3) {
    left: 28px;
    animation-delay: 0;
  }
  @keyframes lds-facebook {
    0% {
      top: 2px;
      height: 32px;
    }
    50%, 100% {
      top: 6px;
      height: 16px;
    }
  }
  

@media screen and (max-width: 1024px) {
    .login_login_container{
        width: 45%;
    }
    .login_signup_container{
        width: 45%;
    }
}