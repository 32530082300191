.navbar {
  background: #fff;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  margin-top: -10px;
  margin-bottom: 4rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}

.navbar-logo {
  color: #034078;
  justify-self: start;
  margin-left: 135px;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  width: 45vw;
  justify-content: center;
  margin-top: 20px;
  margin-right: 3rem;
  align-items: center;
}

.nav-item {
  height: 80px;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 500;
}

.nav-links {
  color: #034078;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 0.6rem;
  height: 100%;
}
.nav-links-right {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 0.6rem;
  height: 100%;
}
.nav-links-right-user{
  color: #034078;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 0.6rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #034078;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #034078;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}
.signUp {
  list-style-type: none;
}
.nav-right {
  display: flex;
  list-style: none;
}
@media screen and (max-width: 1024px) {
  .navbar-logo {
    margin-left: 100px;
  }
  .nav-menu {
    width: 40vw;
    margin-right: 2rem;
    margin-left: 1rem;
    justify-content: flex-start;
  }
  .nav-links {
    padding: 0.5rem 0.3rem;
   
  }
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .navbar-logo {
    margin-left: 20px;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 60vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: rgb(230, 229, 229);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: rgb(253, 252, 252);
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #034078;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #034078;
    padding: 14px 20px;
    border: 1px solid #034078;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: rgb(253, 252, 252);
    color: #242424;
    transition: 250ms;
  }
}
